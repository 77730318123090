<template>
  <div>
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="$refs.modalFornecedor.show(), novoFornecedor=true, clearFornecedor(), edit= false"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Fonecedor
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      ref="modalFornecedor"
      size="lg"
      hide-footer
    >
      <b-row>
        <b-col
          cols="6"
          class="mt-1"
        >
          Fantasia :
          <b-form-input
            v-model="fornecedor.nome_fantasia"
            placeholder="Nome Fantasia"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Razão Social :
          <b-form-input
            v-model="fornecedor.razao_social"
            placeholder="Fornecedor"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          CNPJ :
          <cleave
            v-model="fornecedor.cnpj"
            class="form-control"
            :options="mask.cpfCnpj"
            placeholder="CNPJ"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Inscrição Estadual :
          <b-form-input
            v-model="fornecedor.inscricao_estadual"
            placeholder="Inscrição Estadual"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          CEP :
          <cleave
            v-model="fornecedor.cep"
            placeholder="CEP"
            class="form-control"
            :options="mask.cep"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Endereço :
          <b-form-input
            v-model="fornecedor.endereco"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Observação :
          <b-form-input
            v-model="fornecedor.observacao"
            placeholder="Observação"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Representante :
          <b-form-input
            v-model="fornecedor.representante"
            placeholder="Nome Representante"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Contato Representante :
          <b-form-input
            v-model="fornecedor.contato_representante"
            placeholder="Contato"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Chave PIX :
          <b-form-input
            v-model="fornecedor.chave_pix"
            placeholder="PIX"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Telefone :
          <b-form-input
            v-model="fornecedor.telefone"
            placeholder="Telefone"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Celular :
          <cleave
            v-model="fornecedor.celular"
            class="form-control"
            placeholder="(00) 0000-0000"
            :options="mask.telefone"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Email :
          <b-form-input
            v-model="fornecedor.email"
            placeholder="exemplo@exemplo.com.br"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Site :
          <b-form-input
            v-model="fornecedor.site"
            placeholder="www.exemplo.com.br"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Cidade :
          <b-form-select
            v-model="fornecedor.cidade_id"
            :options="optionsCidades"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Empresa :
          <b-form-select
            v-model="fornecedor.empresa_id"
            :options="optionsEmpresas"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row
        v-if="novoFornecedor"
        align-h="end"
        class="mt-1"
      >
        <b-col cols="auto">
          <b-button
            variant="danger"
            @click="$refs.modalFornecedor.hide()"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            @click="storeFornecedor"
          >
            Cadastrar
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="!novoFornecedor"
        align-h="end"
        class="mt-1"
      >
        <b-col
          cols="auto"
        >
          <b-button
            variant="danger"
            @click="$refs.modalFornecedor.hide()"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="updateFornecedor"
          >
            <feather-icon
              icon="SaveIcon"
            />
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- </b-modal> -->
    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-row align-h="center">
            <b-col cols="8">
              <b-form-input
                v-model="tableFornecedores.filter"
                type="search"
                placeholder="Pesquisar"
                class="mb-2"
                size="sm"
              />
            </b-col>
          </b-row>
          <b-table
            responsive
            hover
            bordered
            striped
            :filter="tableFornecedores.filter"
            :current-page="tableFornecedores.currentPage"
            :per-page="tableFornecedores.perPage"
            :fields="tableFornecedores.fields"
            :items="tableFornecedores.items"
          >
            <template #cell(detalhes)="data">
              <b-row align-h="between">
                <b-col>
                  <feather-icon
                    class="color-icon"
                    icon="EyeIcon"
                    @click="$router.push({name: 'administrativo.contrato', params: {id: data.item.id}})"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    class="color-icon"
                    icon="EditIcon"
                    @click="rowClicked(data.item)"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    class="trash-icon"
                    icon="TrashIcon"
                    @click="deleteFornecedor(data.item)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
          <b-pagination
            v-model="tableFornecedores.currentPage"
            :total-rows="tableFornecedores.totalRows"
            :per-page="tableFornecedores.perPage"
            align="center"
            size="sm"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BPagination,
  BModal,
  VBModal,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import axios from '@/../axios-auth'

export default {
  components: {
    FeatherIcon,
    Cleave,
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BPagination,
    BModal,
    BFormInput,
    BFormSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      novoFornecedor: true,
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cpfCnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        telefone: {
          delimiters: [' ', '-', '-'],
          blocks: [2, 4, 4],
          uppercase: true,
        },
      },
      tableFornecedores: {
        filter: '',
        fields: [
          'id',
          'razao',
          'cnpj',
          'representante',
          { key: 'contato_representante', label: 'Contado do Representante' },
          'telefone',
          'email',
          { key: 'detalhes', label: 'ações', class: 'text-center' },
        ],
        items: [],
        totalRows: 1,
        perPage: 10,
        currentPage: 1,
      },
      fornecedor: {
        nome_fantasia: 'CABARE DO ZE BARATA',
        razao_social: 'CABARE DO ZE BARATA',
        cnpj: 10177133000191,
        inscricao_estadual: 123456789,
        endereco: 'Rua D',
        observacao: 'Preste atenção',
        representante: 'ZE BARATA',
        contato_representante: 99999999,
        chave_pix: 123456789,
        telefone: 99999999,
        celular: 99999999,
        email: 'zebarata@cabarezebarata.com.br',
        site: 'www.zebarataeventos.com.br',
        cep: '65900000',
        cidade_id: 1,
        empresa_id: 1,
        status: true,
      },

      optionsCidades: [],
      optionsEmpresas: [],
      fornecedorId: '',
    }
  },
  created() {
    this.getFornecedores()
    this.getCidades()
    this.getEmpresas()
  },

  methods: {
    clearFornecedor() {
      this.fornecedor = {
        nome_fantasia: '',
        razao_social: '',
        cnpj: '',
        inscricao_estadual: '',
        endereco: '',
        observacao: '',
        representante: '',
        contato_representante: '',
        chave_pix: '',
        telefone: '',
        celular: '',
        email: '',
        site: '',
        cep: '',
        cidade_id: '',
        empresa_id: '',
        status: true,
      }
    },

    // EDITAR FORNECEDOR
    rowClicked(item) {
      // this.clearFornecedor()
      this.fornecedorId = item.id
      axios.get(`api/v1/fornecedor/edit/${item.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.fornecedor = res.data.dados.fornecedores
        })
      this.edit = true
      this.novoFornecedor = false
      this.$refs.modalFornecedor.show()
    },

    // CADASTAR NONO FORNECEDOR
    storeFornecedor() {
      axios.post('api/v1/fornecedor/store', this.fornecedor, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.getFornecedores()
          this.$swal({
            title: 'Fonercedor cadastrado com sucesso',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.getFornecedores()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    // LISTAR EMPRESAS
    getEmpresas() {
      axios.get('api/v1/empresas', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.empresa.map(dt => {
            this.optionsEmpresas.push({
              text: dt.nome_fantasia,
              value: dt.id,
            })
          })
        })
    },

    // LISTAR CIDADES
    getCidades() {
      axios.get('api/v1/cidades', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(dt => {
            this.optionsCidades.push({
              text: dt.nome,
              value: dt.id,
            })
          })
        })
    },

    // FORNECEDORES
    getFornecedores() {
      this.tableFornecedores.items = []
      axios.get('api/v1/fornecedor', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(dt => {
            this.tableFornecedores.items.push({
              id: dt.id,
              representante: dt.representante,
              contato_representante: dt.contato_representante,
              telefone: dt.telefone,
              razao: dt.razao_social,
              cnpj: this.cpfCNPJ(dt.cnpj),
              email: dt.email,
            })
          })
          this.tableFornecedores.totalRows = this.tableFornecedores.items.length
        })
    },

    // DELETAR FORNECEDOR
    deleteFornecedor(item) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar o fornecedor ${item.razao}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/fornecedor/delete/${item.id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data) {
                this.$refs.modalFornecedor.hide()
                this.getFornecedores()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Fornecedor deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }
            })
            .catch(error => {
              if (error.response) {
                this.$swal({
                  title: 'Erro ao deletar Credor!',
                  text: error.response.data.mensagem,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
              } else {
                // Something happened in setting up the request that triggered an Error
              }
            })
        }
      })
    },
    async updateFornecedor() {
      const body = this.fornecedor
      await axios.put(`api/v1/fornecedor/update/${this.fornecedorId}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/jason',
        },
      }).then(() => {
        this.$refs.modalFornecedor.hide()
        this.getFornecedores()
        this.$swal({
          icon: 'success',
          title: 'Salvo!',
          text: 'Empresa salva com sucesso!',
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch(error => {
        this.$swal({
          title: 'Erro ao Salvar!',
          text: error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
  },
}
</script>
